<template>
  <SinglePage
    :class="['template', overviewClass]"
    :page="displayedPage"
    :configId="config.id"
  />
</template>

<script>
import SinglePage from '@/components/SinglePage/SinglePage';
import { EventBus } from '@/utility';

export default {
  name: 'OfferOverview',
  props: {
    config: {
      type: Object,
      required: true,
    },
    pages: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      pageIndex: 0,
      pageInterval: null,
      goToZeroPage: false,
    };
  },
  components: {
    SinglePage,
  },
  computed: {
    displayedPage() {
      return this.pages[this.pageIndex] || [];
    },
    overviewClass() {
      return lowerCase(this.config.id).replace(/ /g, '-');
    },
  },
  mounted() {
    EventBus.$on('pageZero', this.setToGoToZeroPage);
    this.restartInterval();
  },
  methods: {
    setToGoToZeroPage() {
      this.goToZeroPage = true;
    },
    removeInterval() {
      clearInterval(this.pageInterval);
      this.pageInterval = null;
    },
    restartInterval() {
      if (this.pageInterval !== null) {
        this.removeInterval();
      }
      this.startInterval();
    },
    startInterval() {
      if (this.pageInterval === null) {
        EventBus.$emit('sectionStarted');
        this.pageInterval = setInterval(() => {
          if (this.pageIndex < (Math.min(this.config.pages, keys(this.pages).length) - 1)) {
            this.pageIndex += 1;
          } else if (this.goToZeroPage) {
            this.pageIndex = 0;
            this.goToZeroPage = false;
          } else {
            EventBus.$emit('sectionFinished');
          }
        }, (this.config.pageDuration * 1000));
      }
    },
  },
  beforeDestroy() {
    EventBus.$off('pageZero', this.setToGoToZeroPage);
    this.removeInterval();
  },
};
</script>
