import axios from 'axios';
import { routes } from '../../config';

const http = axios.create({
  baseURL: routes.tc,
  timeout: 10000,
});

const TC_DOMAIN = 'b5ee60c9e3bb89a90b40d5b895eb089a';
export default {
  getUrl(lang, companyUuid) {
    const language = {
      'sr-latn': 'sr',
      'mk-latn': 'mk',
    }[lang] || lang;
    return `/translations/${TC_DOMAIN}/locales/${language}/tenants/${companyUuid}?includeLocaleFallback=true`;
  },
  get(language, companyUuid) {
    const url = this.getUrl(language, companyUuid);
    return http.get(url)
      .then(response => response.data)
      .catch((err) => {
        console.warn('Error fetching translations: ', err);
        return [];
      });
  },
  TC_DOMAIN,
};
