import axios from 'axios';
import { Core, Display } from '@nsftx/systems-sdk';

let settings;
export default class SystemsSDK {
  establishConnectionWithHub() {
    return new Promise((resolve, reject) => {
      this.sys.connect().catch(error => reject(new Error(error)));
      this.sys.on('connection', () => resolve());
    });
  }

  async getApplicationConfiguration() {
    const urlParams = new URLSearchParams(window.location.search);
    const appRunUuid = urlParams.get('appRunUuid') || '';

    const display = new Display(this.sys);
    const data = JSON.parse(await display.getApplication(appRunUuid));

    if (!data.config_url) throw new Error('Config not valid, config_url is empty.');

    return axios({
      method: 'GET',
      url: data.config_url,
      auth: {
        username: process.env.VUE_APP_SYSTEMS_USERNAME,
        password: process.env.VUE_APP_SYSTEMS_PASSWORD,
      }
    });
  }

  formatSettingsOutOfQueryParams() {
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

    return {
      app: {
        language: (queryParams.lang || 'en').replace('_', '-'),
        oddsUpdate: Boolean(queryParams.oddsUpdate) || true,
      },
      company: {
        cpvUuid: queryParams.cpvUuid || '1072b9b0-d247-41a2-b64d-967ce5697e54',
        companyUuid: queryParams.companyUuid || 'b99752b3-443c-4c80-b559-945a95c4b805',
        companyName: queryParams.companyName,
      },
    };
  }

  async init() {
    let timeout;

    try {
      const timeoutPromise = new Promise((_, reject) => {
        timeout = setTimeout(() => {
          clearTimeout(timeout);
          reject(new Error("We coudln't establish connection with Systems SDK. Application will use data from query parameters."));
        }, 5000);
      });

      const connectionPromise = (async () => {
        this.sys = new Core('PreMatchVisualization v1');
        await this.establishConnectionWithHub();

        const { data: appCfg } = await this.getApplicationConfiguration();
        const device = await this.sys.deviceState();
        clearTimeout(timeout);


        const companyUuid = device.space.uuid;
        const companyName = device.space.name.replace(/[\s,.]/g, '').toLowerCase();

        return {
          app: {
            language: appCfg.app_config.language,
            oddsUpdate: Boolean(appCfg.app_config.oddsUpdate),
          },
          company: {
            cpvUuid: appCfg.cpv_uuids[0],
            companyUuid,
            companyName,
          },
        };
      })();

      settings = await Promise.race([connectionPromise, timeoutPromise]);
      return settings;
    } catch (error) {
      clearTimeout(timeout);
      settings = this.formatSettingsOutOfQueryParams();
      return settings;
    }
  }
}
