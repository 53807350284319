<template>
  <div
    v-if="match.displayId"
    class="event-view-header">
    <div class="event-view-header-row-wrap">
      <div ref="evId" class="event-view-display-id">
        {{ match.displayId }}
      </div>
      <div class="event-view-match-meta">
        <div ref="eventDateTime" class="event-view-match-date-time">
          <div>{{ match.matchTime }}</div>
          <div>{{ day }} {{ match.day }}/{{ match.month }}</div>
        </div>
        <div
          class="event-view-match-name-category-wrap"
          :style="{ 'padding-right': `${this.IdDateTimeWidth}px` }">
          <div class="event-view-match-name">{{ match.shortName }}</div>
          <div class="event-view-match-sport-category">
            {{ sportName }} / {{ categoryName }} / {{ tournamentName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventViewHeader',
  props: {
    match: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      IdDateTimeWidth: '',
    };
  },
  computed: {
    sportName() {
      return (this.$store.getters.sportById(this.match.idSport) || {}).name;
    },
    categoryName() {
      return (this.$store.getters.categoryById(this.match.idCategory) || {}).name;
    },
    tournamentName() {
      return (this.$store.getters.tournamentById(this.match.idTournament) || {}).name;
    },
    lang() {
      return this.$store.getters.language;
    },
    day() {
      return moment(this.match.date, 'DD.MM.YYYY').locale(this.lang).format('dddd');
    },
  },
  mounted() {
    this.IdDateTimeWidth = this.$refs.eventDateTime.clientWidth + this.$refs.evId.clientWidth + 2;
  },
};
</script>

<style lang="scss">
  .event-view-header {
    display: flex;
    flex-direction: row;
    height: calc(100vh / 10);

    .event-view-header-row-wrap {
      display: flex;
      flex-direction: row;
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;
      flex-grow: 1;
      margin: 0 2px;
      height: calc(100vh / 11.0204);

      .event-view-display-id {
        width: $EventViewDisplayIdWidth;
        font-size: $largerFont;
        font-weight: 700;
        font-family: 'Roboto Condensed Bold';
        color: $black;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        background: $eventViewHeaderDisplayId;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .event-view-match-meta {
        display: flex;
        flex-direction: row;
        margin-left: 2px;
        flex-grow: 1;
        border-radius: 4px;
        background: $eventViewHeaderBackground;

        .event-view-match-date-time {
          display: flex;
          flex-direction: column;
          padding: 0 calc(100vw / 96);
          justify-content: center;
          font-size: $largerFont;
          font-weight: 700;
          font-family: 'Roboto Condensed Bold';
          color: $white;
          text-transform: uppercase;
        }

        .event-view-match-name-category-wrap {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          align-items: center;
          justify-content: center;
          padding-right: calc((100vw / 10.322));

          .event-view-match-name {
            color: $white;
            font-size: $eventViewEventNameFontSize;
            font-family: 'Roboto Condensed Bold';
            font-weight: 700;
            font-style: bold;
            line-height: calc(100vh / 28.421);
            text-transform: uppercase;
          }

          .event-view-match-sport-category {
            color: $white;
            font-family: 'Roboto Condensed';
            font-size: $largeFont;
            line-height: calc(100vh / 38.5);
            margin-top: calc(100vh / 270.27);
          }
        }
      }
    }
  }
</style>
