const update = (matchToUpdate, socketMatch) => {
  let betFound = false;
  let outcomeFound = false;
  const matchBetsIds = map(matchToUpdate.bets, b => b.id);
  const updateBets = filter(socketMatch.matchBets, b => includes(matchBetsIds, b.idBet));
  if (!updateBets.length) return { updated: betFound && outcomeFound, match: matchToUpdate };
  forEach(matchToUpdate.bets, (b) => {
    const updateBet = findBy(updateBets, { idBet: b.id });
    if (!updateBet) return;
    betFound = true;
    forEach(b.outcomes, (bo) => {
      const updateOutcome = findBy(
        updateBet.matchBetOutcomes,
        { idMatchBetOutcome: bo.idMatchBetOutcome },
      );
      if (!updateOutcome) return;
      outcomeFound = true;
      Object.assign(bo, { value: parseFloat(updateOutcome.matchBetOutcomeOddValue).toFixed(2) });
    });
  });
  return { updated: betFound && outcomeFound, match: matchToUpdate };
};

export default update;
