<template>
  <div class="sport-header">
    <div class="sport-header-inner-wrap">
      <div :class="['sport-section-name', `outcomes-offset-${outcomesOffset}`]">
        <span>{{ displayedSection }} / {{ sport.name }}</span>
      </div>
      <div class="markets-row-wrap">
        <HeaderBet
          v-for="(bet, index) in sportBets"
          :key="bet.id + index"
          :bet="bet"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBet from '@/components/SportHeader/HeaderBet';

export default {
  name: 'SportHeader',
  props: {
    idSport: {
      type: String,
      required: true,
    },
    sportBets: {
      type: Array,
      required: true,
      default: () => [],
    },
    outcomesOffset: {
      type: Number,
      required: true,
      default: () => 0,
    },
    configId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      displayedSectionId: '',
    };
  },
  components: {
    HeaderBet,
  },
  computed: {
    sport() {
      return this.$store.getters.sportById(this.idSport);
    },
    config() {
      return this.configId && this.$store.getters.configById(this.configId);
    },
    displayedSection() {
      return this.config.name || '';
    },
  },
};
</script>

<style lang="scss">
  .sport-header {
    display:flex;
    flex-direction: row;
    height: $rowHeight;
    background: $sportHeaderNameDark;

    .sport-header-inner-wrap {
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
      width: 100%;
      display:flex;
      flex-direction: row;

      .sport-section-name {
        height: 100%;
        width: $oneThirdWidth;
        font-size: $largerFont;
        font-weight: bold;
        font-style: italic;
        font-stretch: condensed;
        color: $textColor;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: linear-gradient(to right, $sportHeaderNameLight, $sportHeaderNameDark);
        text-transform: uppercase;

        @for $i from 1 through 15 {
          @include outcomeOffset($i);
        }

        span {
          padding: 0 12px;
        }
      }
    }
  }
  .markets-row-wrap {
    display:flex;
    flex-direction: row;
  }

  .top-offer {
    .sport-header {
      background: $sportHeaderNameDark;
      .sport-header-inner-wrap {
        .sport-section-name {
          background: linear-gradient(to right, $sportHeaderNameLight, $sportHeaderNameDark);
        }
      }
    }
  }

  .timelist {
    .sport-header {
      background: $sportHeaderNameTimelistDark;
      .sport-header-inner-wrap {
        .sport-section-name {
          background: linear-gradient(
            to right,
            $sportHeaderNameTimelistLight,
            $sportHeaderNameTimelistDark
          );
        }
      }
    }
  }
</style>
