<template>
  <div class="clock flex-center">
    {{ hours }}:{{ minutes }}
  </div>
</template>

<script>
export default {
  name: 'HeaderTime',
  data() {
    return {
      hours: this.getHour(),
      minutes: this.getMinutes(),
      timeInterval: null,
    };
  },
  created() {
    // Refresh clock every 5s
    this.timeInterval = setInterval(() => {
      this.hours = this.getHour();
      this.minutes = this.getMinutes();
    }, 5000);
  },
  methods: {
    padZero(num) {
      return `${num < 10 ? '0' : ''}${num}`;
    },
    getHour() {
      return this.padZero(new Date().getHours());
    },
    getMinutes() {
      return this.padZero(new Date().getMinutes());
    },
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  },
};
</script>
