export default {
  bets: {},
  categories: {},
  config: [],
  sports: {},
  tournaments: {},
  matches: [],
  loading: false,
  settings: {},
  clientServiceAlive: null,
  serverServiceAlive: null,
  socketConnected: false,
  sectionsLimits: {},
  eventsSections: [],
  translations: [],
  reloadConfig: false,
};
