<template>
  <div class="event-view-bet">
    <div class="event-view-bet-name-header">
      <div class="event-view-display-id">
        {{ eventBet.displayId }}
      </div>
      <div class="event-view-event-bet-name">
        <span>
          {{ metaBet.name  }} {{ isSpecial ? `(${eventBet.specialValue})` : '' }}
        </span>
      </div>
    </div>
    <div class="event-bet-outcomes-wrap">
      <div
        v-for="outcome in eventBet.outcomes"
        :key="outcome.idMatchBetOutcome"
        :class="`event-view-outcome event-view-outcomes-${eventBet.perBetColumns}`">
        <div class="event-view-outcome-name">
          {{ metaOutcomeName(outcome) }}
        </div>
        <div class="event-view-outcome-value">
          {{ outcome.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventViewBet',
  props: {
    eventBet: {
      type: Object,
      required: true,
    },
  },
  computed: {
    metaBet() {
      return this.$store.getters.betById(this.eventBet.id) || {};
    },
    isSpecial() {
      return this.eventBet.specialValue !== '*'
        && !this.eventBet.betGroupingEnabled;
    },
  },
  methods: {
    metaOutcomeName(outcome) {
      return (outcome.shortName || outcome.name)
        || (findBy(this.metaBet.outcomes, { id: outcome.id }) || {}).name || '';
    },
  },
};
</script>

<style lang="scss">
.event-view-bets-column {
  .event-view-bet {
    display: flex;
    flex-direction: column;
    position: relative;

    .event-view-bet-name-header {
      width: 100%;
      display: flex;
      flex-grow: 1;
      margin: 1px 0;
      height: calc(#{$rowHeight} - 2px);
      font-weight: bold;
      overflow: hidden;

      .event-view-display-id,
      .event-view-event-bet-name {
        font-size: $largerFont;
        font-weight: 700;
        font-family: 'Roboto Condensed Bold';
        align-items: center;
        display: flex;
      }

      .event-view-display-id {
        width: $EventViewDisplayIdWidth;
        color: $black;
        background: $white;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        justify-content: center;
      }

      .event-view-event-bet-name {
        margin-left: 2px;
        background: $eventViewBetHeaderBackground;
        flex-grow: 1;
        max-width: calc(100% - #{$EventViewDisplayIdWidth});
        border-radius: 4px;
        text-transform: uppercase;
        padding: 0px 8px;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .event-bet-outcomes-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -1px;
      position: relative;

      .event-view-outcome {
        display: flex;
        margin: 1px;
        height: calc(#{$rowHeight} - 2px);
        background: $charcoal-grey;
        border-radius: 4px;
        justify-content: space-between;
        padding: 0 16px;

        @for $i from 1 through 3 {
          &.event-view-outcomes-#{$i} {
            width: calc((100% / #{$i}) - 34px);
          }
        }

        .event-view-outcome-name,
        .event-view-outcome-value {
          font-size: $largerFont;
          font-family: 'Roboto Condensed';
          align-items: center;
          display: flex;
        }

        .event-view-outcome-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: relative;
          flex-grow: 1;

          &:after {
            content: '';
            display: block;
            width: calc(100vw / 64);
            height: 100%;
            right: 0;
            background: linear-gradient(to right, transparent, $charcoal-grey);
            position: absolute;
          }
        }
      }
    }
  }
}
</style>
