import { sectionsIds } from '@/utility/helpers';

export default {
  categories: state => state.categories,
  categoryById: state => categoryId => (state.categories && state.categories[categoryId]) || {},
  categoryIsoName: state => categoryId => (
    (state.categories && state.categories[categoryId]) || {}
  ).isoName || 'int',
  sports: state => state.sports,
  sportById: state => idSport => (state.sports && state.sports[idSport]) || {},
  tournaments: state => state.tournaments,
  tournamentById: state => tournamentId => (
    state.tournaments && state.tournaments[tournamentId]
  ) || {},
  timelistMatches: state => sortBy(
    filter(state.matches, m => m.sectionId === sectionsIds.TIMELIST),
    ['sportPosition', 'year', 'month', 'day', 'matchTime', 'categoryPosition', 'tournamentPosition', 'shortName'],
  ),
  topOfferMatches: state => sortBy(
    filter(state.matches, m => m.sectionId === sectionsIds.TOP_OFFER),
    ['sportPosition', 'year', 'month', 'day', 'categoryPosition', 'tournamentPosition', 'matchTime', 'shortName'],
  ),
  eventViewMatches: state => sortBy(
    filter(state.matches, m => m.sectionId === sectionsIds.EVENT_VIEW && m.bets.length),
    ['sportPosition', 'sportName', 'timestampUTC'],
  ),
  sportBets: (state, getters) => (idSport, configId) => {
    const betsDisplayLimit = 16;
    const maxOutcomesDisplayed = 16;
    let currentOutcomes = 0;
    const config = getters.configById(configId) || {};
    const templateOutcomes = config.outcomes || [];
    const templateSpecials = config.specialValues || [];
    const sportBets = JSON.parse(JSON.stringify(
      filter(state.bets, b => b.idSport === idSport && b.outcomes.length
      && some(b.outcomes, o => includes(templateOutcomes, o.id))),
    ));
    const displayLimitedBets = take(sportBets, betsDisplayLimit);
    const limitedBets = [];
    for (let i = 0; i < displayLimitedBets.length; i += 1) {
      if (currentOutcomes === maxOutcomesDisplayed) break;
      const bet = JSON.parse(JSON.stringify(displayLimitedBets[i]));
      const betSpecial = findBy(templateSpecials, { id_bet: bet.id }) || {};
      bet.specialType = betSpecial.type;
      bet.outcomes = orderBy(filter(bet.outcomes, o => includes(templateOutcomes, o.id)), 'position');
      if (bet.specialType === 'FIXED') {
        const loopLength = (betSpecial.values || []).length;
        for (let j = 0; j < loopLength; j += 1) {
          const betSpecialValue = betSpecial.values[j];
          const newBet = Object.assign({}, bet, { specialValue: betSpecialValue });
          newBet.displayId = (findBy(bet.displayIds, { specialValue: betSpecialValue }) || {}).id || ' - ';
          if (currentOutcomes + newBet.outcomes.length <= maxOutcomesDisplayed) {
            currentOutcomes += newBet.outcomes.length;
            limitedBets.push(newBet);
          } else if (currentOutcomes < maxOutcomesDisplayed) {
            const remainingCols = maxOutcomesDisplayed - currentOutcomes;
            if (remainingCols > 1) {
              newBet.outcomes = orderBy(newBet.outcomes.slice(0, remainingCols), 'position');
              currentOutcomes += newBet.outcomes.length;
              limitedBets.push(newBet);
            }
          }
        }
      } else {
        bet.displayId = ((bet.displayIds && bet.displayIds[0]) || {}).id || ' - ';
        const isMostBalanced = bet.specialType && bet.specialType === 'MOST_BALANCED';
        const outcomesLength = isMostBalanced
          ? bet.outcomes.length + 1
          : bet.outcomes.length;
        if (currentOutcomes + outcomesLength <= maxOutcomesDisplayed) {
          currentOutcomes += outcomesLength;
          limitedBets.push(bet);
        } else if (currentOutcomes < maxOutcomesDisplayed) {
          const remainingCols = maxOutcomesDisplayed - currentOutcomes;
          if (remainingCols > 1) {
            const possibleOutcomes = isMostBalanced ? remainingCols - 1 : remainingCols;
            bet.outcomes = orderBy(bet.outcomes.slice(0, possibleOutcomes), 'position');
            currentOutcomes += bet.outcomes.length;
            limitedBets.push(bet);
          }
        }
      }
    }
    return limitedBets;
  },
  configById: state => configId => findBy(state.config, { id: configId }),
  configSections: state => filter(
    map(orderBy(state.config, 'position'), c => c.id),
    s => includes(state.eventsSections, s),
  ),
  cpvUuid: state => state.settings.company.cpvUuid,
  companyUuid: state => state.settings.company.companyUuid,
  companyName: state => state.settings.company.companyName,
  oddsUpdate: state => state.settings.app.oddsUpdate,
  language: state => state.settings.app.language,
  sectionsLimits: state => state.sectionsLimits,
  eventsSections: state => state.eventsSections,
  betById: state => betId => findBy(state.bets, { id: betId }),
  translate: state => key => state.translations[key] || key,
  shouldConfigUpdate: state => state.reloadConfig,
};
