const getOfferPages = (matches = [], matchesLimit = 17) => {
  if (!matches.length) return {};
  const maxMatches = matches.slice(0, matchesLimit);
  const maxPageRows = 19;
  const maxPageRowsMinus2 = maxPageRows - 2;
  const maxPageRowsMinus3 = maxPageRows - 3;
  const offerPages = {};
  let currentSportId = null;
  let currentPage = 0;
  let currentPageRows = 0;
  let currentDate = 0;
  const addSport = (sportId) => {
    currentSportId = sportId;
    currentPageRows += 2;
  };
  const createPageArray = () => {
    if (!offerPages[currentPage]) {
      offerPages[currentPage] = [];
    }
  };
  const addPage = () => {
    currentPage += 1;
    currentPageRows = 0;
    createPageArray();
  };
  const addMatch = (match) => {
    offerPages[currentPage].push(match);
    currentPageRows += 1;
  };
  const setDate = (date) => {
    currentDate = date;
  };
  const newPage = (match) => {
    addPage();
    addSport(match.idSport);
    setDate(match.date);
    addMatch(match);
  };

  const matchesLength = maxMatches.length;
  for (let i = 0; i < matchesLength; i += 1) {
    const match = maxMatches[i];
    if (i === 0) {
      createPageArray();
      addSport(match.idSport);
      addMatch(match);
      setDate(match.date);
    } else if (currentPageRows < maxPageRows) {
      if (currentSportId === match.idSport) {
        if (currentDate === match.date) {
          addMatch(match);
        } else if (currentPageRows <= maxPageRowsMinus2) {
          setDate(match.date);
          currentPageRows += 1;
          addMatch(match);
        } else {
          newPage(match);
        }
      } else if (currentPageRows <= maxPageRowsMinus3) {
        if (currentDate === match.date) {
          addSport(match.idSport);
          addMatch(match);
        } else {
          setDate(match.date);
          addSport(match.idSport);
          addMatch(match);
        }
      } else {
        newPage(match);
      }
    } else {
      newPage(match);
    }
  }
  return offerPages;
};

export default getOfferPages;
