<template>
  <div class="outcomes-header">
    <div class="outomes-inner-wrap">
      <div :class="['outcomes-section-name', `outcomes-offset-${outcomesOffset}`]">
        <span class="group-date">{{ day }} {{ date }}</span>
      </div>
      <div class="outcomes-header-markets-row-wrap">
        <BetOutcomes
          v-for="(bet, index) in sportBets"
          :key="bet.id + index"
          :sportBet="bet" />
      </div>
    </div>
  </div>
</template>

<script>
import BetOutcomes from './BetOutcomes';

export default {
  name: 'OutcomesHeader',
  components: {
    BetOutcomes,
  },
  props: {
    idSport: {
      type: String,
      required: true,
    },
    groupDate: {
      type: String,
      required: true,
    },
    sportBets: {
      type: Array,
      required: true,
      default: () => [],
    },
    outcomesOffset: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  data() {
    return {
      headerDate: moment(this.groupDate, 'DD.MM.YYYY'),
    };
  },
  computed: {
    date() {
      return this.headerDate.format('DD.MM.');
    },
    lang() {
      return this.$store.getters.language;
    },
    day() {
      return moment(this.headerDate).locale(this.lang).format('dddd');
    },
  },
};
</script>

<style lang="scss">
  .outcomes-header {
    display:flex;
    flex-direction: row;
    height: $rowHeight;
    background: $outcomesHeaderBackground;

    .outomes-inner-wrap {
      display:flex;
      flex-direction: row;
      width: 100%;
      border-top: 1px solid;
      border-bottom: 1px solid;

      .outcomes-section-name {
        width: $oneThirdWidth;
        height: 100%;
        box-shadow: inset -2px 0px 0px -1px rgba(255,255,255,0.3);

        @for $i from 1 through 15 {
          @include outcomeOffset($i);
        }

        .group-date {
          height: 100%;
          padding: 0 12px;
          font-size: $largeFont;
          font-weight: bold;
          font-style: italic;
          font-stretch: condensed;
          color: $textColor;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-transform: uppercase;
        }
      }

      .outcomes-header-markets-row-wrap {
        display:flex;
        flex-direction: row;
      }
    }
  }

  .top-offer {
    .outcomes-header {
      background: $outcomesHeaderBackground;
    }
  }

  .timelist {
    .outcomes-header {
      background: $outcomesHeaderBackgroundTimelist;
    }
  }
</style>
