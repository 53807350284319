const date = moment().utc();

const cpvUuid = process.env.SERVER === 'staging'
  ? '1072b9b0-d247-41a2-b64d-967ce5697e54'
  : '01a2090e-3d0e-4699-bedf-eb84039b6eaf';

const companyUuid = process.env.SERVER === 'staging'
  ? 'b99752b3-443c-4c80-b559-945a95c4b805'
  : 'b98c4fbe-b108-4a58-96d7-e98f5d53e74d';

export default {
  metaParams: {
    dataFormat: {
      default: 'object',
      sports: 'object',
      categories: 'object',
      tournaments: 'object',
      matches: 'array',
      betGroups: 'array',
    },
    dataShrink: !process.env.DEBUG,
    language: { default: 'en' },
    params: {
      start_date: date.format(),
      end_date: date.clone().add(7, 'd').endOf('d').format(),
      delivery_platform: 'Web',
      company_uuid: companyUuid,
    },
  },
  configParams: {
    ...process.env.SERVER === 'staging' && { cpvUuid },
    cpv: cpvUuid,
    locale: 'en',
  },
  topOfferParams: {
    dataFormat: {
      default: 'object',
      sports: 'object',
      categories: 'object',
      tournaments: 'object',
      matches: 'array',
      betGroups: 'array',
    },
    dataShrink: !process.env.DEBUG,
    language: { default: 'en' },
    params: {
      start_date: date.format(),
      end_date: date.clone().add(7, 'd').endOf('d').format(),
      delivery_platform: 'Web',
      company_uuid: companyUuid,
    },
    topOffer: { matches: true },
  },
  matchesParams: {
    dataFormat: {
      default: 'object',
      sports: 'object',
      categories: 'object',
      tournaments: 'object',
      matches: 'array',
      betGroups: 'array',
    },
    dataShrink: !process.env.DEBUG,
    language: { default: 'en' },
    params: {
      start_date: date.format(),
      end_date: date.clone().add(7, 'd').endOf('d').format(),
      delivery_platform: 'Web',
      id_sport: '4ac43657-d99c-4e45-a16e-807d3dedafe1',
      bet_count: 3,
      include_meta: true,
      company_uuid: companyUuid,
    },
  },
};
