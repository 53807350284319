const perBetColumns = (outcomesLength = 0, isSpecial = false) => {
  if (isSpecial) {
    if (outcomesLength > 2) {
      return 3;
    }
    return 2;
  }
  if (outcomesLength >= 3 && outcomesLength !== 4) {
    return 3;
  }
  if (outcomesLength === 2 || outcomesLength === 4) {
    return 2;
  }
  return 2;
};

const getEventBetsColumns = (match = {}) => {
  const matchBets = match.bets || [];
  const betsLength = matchBets.length;
  const MAX_COLUMNS = 3;
  const MAX_ROWS = 17;
  const MAX_ROWS_MINUS_1 = MAX_ROWS - 1;
  const MAX_ROWS_MINUS_2 = MAX_ROWS - 2;
  const betsColumns = {};
  for (let colIndex = 0; colIndex < MAX_COLUMNS; colIndex += 1) {
    betsColumns[colIndex] = [];
  }
  if (!(matchBets || []).length) return betsColumns;
  let currentColumn = 0;
  let currentRow = 0;
  const newColumn = () => {
    currentColumn += 1;
    currentRow = 0;
  };
  const addBet = (bet) => {
    if (currentColumn >= MAX_COLUMNS) return;
    betsColumns[currentColumn].push(bet);
  };
  const increaseRow = (rows) => {
    currentRow += rows;
  };
  for (let i = 0; i < betsLength; i += 1) {
    const matchBet = JSON.parse(JSON.stringify(matchBets[i]));
    const betOutcomesLenght = (matchBet.outcomes || []).length;
    if (currentColumn >= MAX_COLUMNS) break;
    if (betOutcomesLenght === 0) continue;
    const rowColumns = matchBet.betGroupingEnabled
      ? matchBet.ungroupedOutcomesLength
      : betOutcomesLenght;
    const outcomeColumns = perBetColumns(rowColumns, matchBet.specialValue !== '*');
    const outcomeRows = Math.ceil(betOutcomesLenght / outcomeColumns);
    matchBet.perBetColumns = outcomeColumns;
    if ((currentRow + (outcomeRows + 1)) <= MAX_ROWS) {
      increaseRow(outcomeRows + 1);
      addBet(matchBet);
    } else if (currentRow <= MAX_ROWS_MINUS_2) {
      // calculate how many outcomes can fit in the current column
      const remainingRows = MAX_ROWS_MINUS_1 - currentRow;
      const betForCurrentColumn = JSON.parse(JSON.stringify(matchBet));
      const betForNextColumn = JSON.parse(JSON.stringify(matchBet));
      const outcomesToKeep = outcomeColumns * remainingRows;
      betForCurrentColumn.outcomes = matchBet.outcomes.slice(0, outcomesToKeep);
      addBet(betForCurrentColumn);
      // add the bet with remaining outcomes to new page
      if (currentColumn < (MAX_COLUMNS - 1)) {
        newColumn();
        betForNextColumn.outcomes = matchBet.outcomes.slice(outcomesToKeep);
        addBet(betForNextColumn);
        increaseRow(Math.ceil(betForNextColumn.outcomes.length / outcomeColumns) + 1);
      }
    } else if ((currentRow > MAX_ROWS_MINUS_2) && (currentColumn < (MAX_COLUMNS - 1))) {
      newColumn();
      increaseRow(outcomeRows + 1);
      addBet(matchBet);
    }
  }
  return betsColumns;
};

export default getEventBetsColumns;
