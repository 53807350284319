import parserMap from './parser-map';
import { maxInt } from '../helpers';

const categoryMap = parserMap.category;

class Category {
  constructor(rawObj) {
    this.id = rawObj[categoryMap.id];
    this.idSport = rawObj[categoryMap.idSport];
    this.name = rawObj[categoryMap.name];
    this.shortName = rawObj[categoryMap.shortName] || this.name;
    // this.isoId = rawObj[categoryMap.isoId];
    this.isoName = rawObj[categoryMap.isoName];
    this.position = parseInt(rawObj[categoryMap.position], 10) || maxInt;
    // this.numberOfMatches = parseInt(rawObj[categoryMap.numberOfMatches], 10);
    // this.providerId = rawObj[categoryMap.providerId];
    // this.active = true;
  }
}

export default Category;
