import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from './App';
import store from './store';
import router from './router';
import { sentryConfig } from '../config';
import SystemsSDK from './utility/systemsSDK';

Vue.config.productionTip = false;


Sentry.init({
  dsn: sentryConfig.url,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
});

// eslint-disable-next-line no-new
new Vue({
  store,
  router,
  async beforeCreate() {
    const settings = await new SystemsSDK().init();
    this.$store.dispatch('updateSettings', settings);

    this.$mount('#app');
  },
  render: h => h(App),
});
