<template>
  <div class="header-row">
    <div class="header-inner-wrap">
      <div class="logo-wrap">
        <CompanyLogo />
      </div>
      <div class="title-wrap">
        {{ appTitle }}
      </div>
      <div class="time-wrap">
        <HeaderTime />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTime from './HeaderTime';
import CompanyLogo from './CompanyLogo';

export default {
  name: 'AppHeader',
  components: {
    CompanyLogo,
    HeaderTime,
  },
  computed: {
    appTitle() {
      return this.$store.getters.translate('general_prematch');
    },
  },
};
</script>

<style lang="scss">
  .header-row {
    background-color: $backgroundColor;
    display: flex;
    flex-direction: row;
    opacity: 0.9;
    height: $rowHeight;

    .header-inner-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      border-bottom: 1px solid $borderColor;

      .logo-wrap,
      .title-wrap,
      .time-wrap {
        display: flex;
        padding: 0 1.25rem;
        justify-content: center;
        flex-direction: column;
        font-size: calc(100vh / 41.5);
        color: $textColor;
      }

      .logo-wrap {
        max-height: $rowHeight;
        overflow: hidden;
      }

      .title-wrap {
        text-transform: uppercase;
      }

      .time-wrap {
        font-size: $largeFont;
        font-weight: bold;
        opacity: 0.5;
      }
    }
  }
</style>
