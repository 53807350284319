export default {
  CLIENT_SERVICE_ALIVE: 'CLIENT_SERVICE_ALIVE',
  SAVE_TRANSLATIONS: 'SAVE_TRANSLATIONS',
  SERVER_SERVICE_ALIVE: 'SERVER_SERVICE_ALIVE',
  SET_CONFIGURATION: 'SET_CONFIGURATION',
  SET_MATCHES: 'SET_MATCHES',
  SET_META: 'SET_META',
  SOCKET_CONNECTED: 'SOCKET_CONNECTED',
  SOCKET_DISCONNECTED: 'SOCKET_DISCONNECTED',
  TOGGLE_LOADER: 'TOGGLE_LOADER',
  TOGGLE_CONFIG_UPDATE: 'TOGGLE_CONFIG_UPDATE',
  UPDATE_MATCH_BETS: 'UPDATE_MATCH_BETS',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
};
