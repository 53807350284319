import Vue from 'vue';
import types from './mutationTypes';
import { socket } from '@/utility';
import { sectionsIds, matchUpdater } from '@/utility/helpers';

export default {
  [types.SET_CONFIGURATION](state, payload) {
    const sectionsLimits = {};
    forEach(payload, (p) => {
      sectionsLimits[p.id] = p.id === sectionsIds.EVENT_VIEW ? p.pages : p.pages * 17;
    });
    state.sectionsLimits = sectionsLimits;
    Vue.set(state, 'config', payload);
  },
  [types.SET_MATCHES](state, payload) {
    const eventsSections = Array.from(new Set(payload.map(({ sectionId }) => sectionId)));
    Vue.set(state, 'eventsSections', eventsSections);
    Vue.set(state, 'matches', payload);
  },
  [types.SET_META](state, payload) {
    if (!isEmpty(payload.bets)) {
      Vue.set(state, 'bets', payload.bets);
    }
    if (!isEmpty(payload.categories)) {
      Vue.set(state, 'categories', payload.categories);
    }
    if (!isEmpty(payload.sports)) {
      Vue.set(state, 'sports', payload.sports);
    }
    if (!isEmpty(payload.tournaments)) {
      Vue.set(state, 'tournaments', payload.tournaments);
    }
  },
  [types.TOGGLE_LOADER](state) {
    state.loading = !state.loading;
  },
  [types.UPDATE_SETTINGS](state, payload) {
    Vue.set(state, 'settings', payload);
  },
  [types.CLIENT_SERVICE_ALIVE](state, cpvUuid) {
    clearInterval(state.clientServiceAlive);
    const clientServiceAlive = setInterval(() => {
      socket.emit({
        event: 'message',
        options: {
          type: 'serviceAlive',
        },
        settings: {
          company: {
            cpv: cpvUuid,
          },
        },
      });
    }, 15000);

    Vue.set(state, 'clientServiceAlive', clientServiceAlive);
  },
  [types.SERVER_SERVICE_ALIVE](state) {
    // Reconnect if backend service has issues
    clearTimeout(state.serverServiceAlive);
    const serverServiceAlive = setTimeout(() => {
      console.warn('Server service alive issue => ', new Date());
      socket.disconnect();
      setTimeout(() => {
        socket.connect();
      }, 1000);
    }, 30000);

    Vue.set(state, 'serverServiceAlive', serverServiceAlive);
  },
  [types.SOCKET_CONNECTED](state) {
    console.log('Socket connected!');
    state.connected = true;
  },
  [types.SOCKET_DISCONNECTED](state) {
    console.log('Socket disconnected!');
    clearTimeout(state.serverServiceAlive);
    clearInterval(state.clientServiceAlive);
    state.connected = false;
  },
  [types.UPDATE_MATCH_BETS](state, { matchIndex, match }) {
    const matchToUpdate = JSON.parse(JSON.stringify(state.matches[matchIndex]));
    const update = matchUpdater(matchToUpdate, match);
    if (update.updated) {
      state.matches.splice(matchIndex, 1, update.match);
    }
  },
  [types.SAVE_TRANSLATIONS](state, data) {
    Vue.set(state, 'translations', data);
  },
  [types.TOGGLE_CONFIG_UPDATE](state, payload) {
    state.reloadConfig = payload;
  },
};
