<template>
  <div class="logo">
    <img
      class="logo-image"
      v-if="logoSource && !urlError"
      :src="logoSource"
      :alt="companyName"
      @error="() => urlError = true" />
    <div
      class="logo-fallback-text"
      v-if="urlError">
      {{ companyName }}
    </div>
  </div>
</template>

<script>
import { routes, gravitySlug } from '../../../config';

export default {
  name: 'CompanyLogo',
  data() {
    return {
      urlError: false,
    };
  },
  computed: {
    companyUuid() {
      return this.$store.getters.companyUuid;
    },
    companyName() {
      return this.$store.getters.companyName;
    },
    container() {
      return this.companyName
        ? `${this.companyName}_logo-images`
        : '';
    },
    gravityCompany() {
      return this.companyUuid
        ? `${gravitySlug[this.companyUuid] || this.companyName}`
        : '';
    },
    logoSource() {
      return this.companyUuid && this.companyName
        ? `${routes.cms}/${this.gravityCompany}/${this.container}/prematch-visualization_logo.png`
        : '';
    },
  },
};
</script>
<style lang="scss">
.logo {
  max-height: $rowHeight;
  justify-content: center;
  display: flex;

  .logo-image {
    max-height: calc(#{$rowHeight} - 10px);
  }

  .logo-fallback-text {
    text-transform: uppercase;
  }
}
</style>
