module.exports = {
  development: {
    offer: 'https://sportbook-platform-api-dev.nsoft.com/prematchVisualization',
    config: 'https://sportsbook-public-api-dev.nsoft.com/prematchVisualization.php',
    socket: 'https://sportsbook-public-api-dev.nsoft.com',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/development',
    tc: 'https://frasier.de-2.nsoft.cloud',
  },
  staging: {
    offer: 'https://sportsbook-platform-api-staging.nsoft.com/prematchVisualization',
    config: 'https://sportsbook-public-api-staging.nsoft.com/prematchVisualization.php',
    socket: 'https://sportsbook-public-api-staging.nsoft.com',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/staging',
    tc: 'https://frasier.de-2.nsoft.cloud',
  },
  production: {
    offer: 'https://sportsbook-platform-api.nsoft.com/prematchVisualization',
    config: 'https://sportsbook-public-api.nsoft.com/prematchVisualization.php',
    socket: 'https://sportsbook-public-api.nsoft.com',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/production',
    tc: 'https://frasier.de-2.nsoft.cloud',
  },
};
