import api from '@/api';
import types from './mutationTypes';
import { decompress, socket, parser } from '@/utility';

let firstLoad = true;

export default {
  loadConfiguration({ commit, getters, dispatch }, { companyUuid }) {
    dispatch('toggleLoader');
    return api.loadConfiguration({ companyUuid, language: getters.language })
      .then((response) => {
        commit(types.SET_META, parser.formatMeta(response.included));
        commit(types.SET_CONFIGURATION, response.data);
        dispatch('toggleConfigUpdate', false);
      })
      .catch(err => Promise.reject(err));
  },
  loadMatches({ commit, getters, dispatch }, { companyUuid, sectionsLimits }) {
    return api.loadMatches({ companyUuid, sectionsLimits, language: getters.language })
      .then((response) => {
        commit(types.SET_META, parser.formatMeta(response.data.included));
        commit(types.SET_MATCHES, parser.formatMatches(response.data));
        dispatch('toggleLoader');
        firstLoad = false;
      })
      .catch(err => Promise.reject(err));
  },
  updateSettings({ commit }, settings) {
    commit(types.UPDATE_SETTINGS, settings);
  },
  socketInstantiate({ getters, dispatch }) {
    socket.instance(getters.cpvUuid, dispatch);
    socket.bindWindowListeners();
  },
  socketMessage({ commit, state, dispatch }, message) {
    const { meta } = message;
    const data = (((meta || {}).contentEncoding || '').toUpperCase() === 'DEFLATE')
      ? decompress(message.data) : message.data;

    const updateMatch = (match) => {
      const i = findIndex(state.matches, { id: match.idMatch });
      if (i > -1) {
        commit(types.UPDATE_MATCH_BETS, { matchIndex: i, match });
      }
    };

    switch (message.type) {
      case 'state': {
        commit(types.SERVER_SERVICE_ALIVE);
        break;
      }
      case 'serviceAlive':
        commit(types.SERVER_SERVICE_ALIVE);
        break;
      case 'updateMatchBets':
        updateMatch(data);
        break;
      case 'visualizationSettingsSave':
        dispatch('toggleConfigUpdate', true);
        break;
      default: break;
    }
  },
  socketConnected({ commit, getters }) {
    commit(types.SOCKET_CONNECTED);
    commit(types.CLIENT_SERVICE_ALIVE, getters.cpvUuid);
  },
  socketDisconnected({ commit }) {
    commit(types.SOCKET_DISCONNECTED);
  },
  saveTranslations({ commit }, data) {
    commit(types.SAVE_TRANSLATIONS, data);
  },
  toggleLoader({ commit }) {
    if (firstLoad) commit(types.TOGGLE_LOADER);
  },
  toggleConfigUpdate({ commit }, payload) {
    commit(types.TOGGLE_CONFIG_UPDATE, payload);
  },
};
