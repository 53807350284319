<template>
  <div
    class="outcomes-bet-outcome-name"
    :class="[
      'outcomes-bet-outcome-name',
      { 'long-outcome-name': longOutcomeName },
    ]"
    ref="outcomeNameDiv">
    <span ref="outcomeNameSpan">{{ outcome.name }}</span>
  </div>
</template>

<script>
export default {
  name: 'BetOutcome',
  props: {
    outcome: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      longOutcomeName: false,
    };
  },
  mounted() {
    this.outcomeNameDivHeight = this.$refs.outcomeNameDiv.clientHeight;
    this.outcomeNameSpanHeight = this.$refs.outcomeNameSpan.clientHeight;
    this.longOutcomeName = this.outcomeNameSpanHeight > this.outcomeNameDivHeight;
  },
};
</script>

<style lang="scss">
  .outcomes-header-bet-outcomes {
    .outcomes-bet-outcome-name {
      width: calc(#{$outcomeWidth} - 20px);
      color: $textColor;
      font-size: $mediumFont;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      text-align: center;
      padding: 0 10px;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.long-outcome-name {
        align-items: baseline;

        span {
          padding: 1px 0;
          line-height: calc(100vh / 41);
          overflow: hidden;
        }
      }
    }
  }
</style>
