import axios from 'axios';
import paramsHelper from './paramsHelper';
import { routes } from '../../config';

const http = axios.create({
  baseURL: routes.offer,
  timeout: 60000,
});

export default {
  loadConfiguration({ companyUuid, language }) {
    return http.get('/settings', {
      params: {
        params: {
          company_uuid: companyUuid,
        },
        include: 'sports,bets,outcomes',
        language: paramsHelper.language(language),
      },
    })
      .then(response => response.data)
      .catch((error) => {
        console.error('Error fetching settings', error.toJSON());
        return Promise.reject(error.toJSON());
      });
  },
  loadMatches({ companyUuid, sectionsLimits, language }) {
    return http.get('/matches', {
      params: {
        language: paramsHelper.language(language),
        params: {
          ...paramsHelper.commonParams(companyUuid),
        },
        ...paramsHelper.pageDisplayMatches(sectionsLimits),
        ...paramsHelper.matchesParams,
      },
    })
      .then(response => response)
      .catch((error) => {
        console.error('Error fetching matches', error.toJSON());
        return Promise.reject(error.toJSON());
      });
  },
};
