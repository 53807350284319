<template>
  <div class="offer-single-page">
    <SportWrap
      v-for="idSport in pageSports"
      :key="idSport"
      :idSport="idSport"
      :configId="configId"
      :matches="matchesBySport(idSport)"
    />
  </div>
</template>

<script>
import SportWrap from '@/components/SportWrap/SportWrap';

export default {
  name: 'SinglePage',
  props: {
    page: {
      type: Array,
      required: true,
      default: () => [],
    },
    configId: {
      type: String,
      required: true,
    },
  },
  components: {
    SportWrap,
  },
  computed: {
    pageSports() {
      return Array.from(new Set(map(this.page, p => p.idSport)));
    },
  },
  methods: {
    matchesBySport(idSport) {
      return filter(this.page, { idSport });
    },
  },
};
</script>
