import parserMap from './parser-map';

const teamMap = parserMap.match.team;

class Team {
  constructor(rawObj) {
    this.id = rawObj[teamMap.id];
    this.type = rawObj[teamMap.type];
    this.name = rawObj[teamMap.name];
    this.shortName = rawObj[teamMap.shortName] || this.name;
    this.provider = rawObj[teamMap.provider];
    this.active = true;
  }
}

export default Team;
