<template>
  <div :class="['event-row', { even: rowIndex % 2, odd: !(rowIndex % 2) }]">
    <div class="row-inner-wrap">
      <div :class="['match-section-name', `outcomes-offset-${outcomesOffset}`]">
        <div class="row-pre-element">
          <div class="row-pre-detail"></div>
        </div>
        <div ref="eventDetWrap" class="event-name-details-wrap">
          <div ref="eventMeta" class="event-details-meta">
            <span class="event-display-id">{{ match.displayId }}</span>
            <span class="event-time">{{ match.matchTime }}</span>
            <div class="flag-wrap">
              <span :class="['flag', `flag-${categoryIsoName}`]"></span>
            </div>
          </div>
          <div
            ref="eventName"
            class="event-name">
            <div
              ref="homeTeamDiv"
              :class="['home-team', { long: longHomeTeam, 'flex-width': flexHomeTeam }]">
              <span ref="homeTeamSpan">{{ match.homeTeamShortName }}</span>
              <div
                v-if="longHomeTeam && !flexHomeTeam && configId === 'TIMELIST'"
                class="home-team-fade-out"
                :style="`
                  background: linear-gradient(to right, transparent, ${this.stepColor});
                `"></div>
            </div>
            <div
              ref="dashSep"
              class="dash-separator">-</div>
            <div
              ref="awayTeamDiv"
              class="away-team">
              <span ref="awayTeamSpan">{{ match.awayTeamShortName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-odds-wrap">
      <div class="event-odds-inner">
        <EventBet
          v-for="(sportBet, index) in sportBets"
          :key="sportBet.id + index"
          :sportBet="sportBet"
          :eventBet="getMatchBet(sportBet)" />
      </div>
    </div>
  </div>
</template>

<script>
import { colorMap } from '@/mixins';
import EventBet from '@/components/EventRow/EventBet';

export default {
  name: 'EventRow',
  props: {
    match: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    outcomesOffset: {
      type: Number,
      required: true,
      default: () => 0,
    },
    sportBets: {
      type: Array,
      required: true,
      default: () => [],
    },
    configId: {
      type: String,
      required: true,
    },
  },
  components: {
    EventBet,
  },
  data() {
    return {
      longHomeTeam: false,
      flexHomeTeam: false,
      stepColor: '',
    };
  },
  computed: {
    categoryIsoName() {
      return lowerCase(this.$store.getters.categoryIsoName(this.match.idCategory));
    },
  },
  mounted() {
    this.eventNameWidth = this.$refs.homeTeamDiv.clientWidth
      + this.$refs.awayTeamDiv.clientWidth;
    this.divWidth = this.$refs.homeTeamDiv.clientWidth;
    this.homeSpanWidth = this.$refs.homeTeamSpan.clientWidth;
    this.awaySpanWidth = this.$refs.awayTeamSpan.clientWidth;
    this.longHomeTeam = this.homeSpanWidth > this.divWidth;
    this.flexHomeTeam = this.eventNameWidth > (this.awaySpanWidth + this.homeSpanWidth);

    if (this.longHomeTeam && !this.flexHomeTeam && this.configId === 'TIMELIST') {
      this.eventWrapWidth = this.$refs.eventDetWrap.clientWidth;
      this.eventMetaWidth = this.$refs.eventMeta.clientWidth;
      this.ratio = ((this.eventMetaWidth + this.divWidth) / this.eventWrapWidth).toFixed(2);
      this.stepColor = this.rowIndex % 2 !== 0
        ? colorMap.oddCMap(this.ratio)
        : colorMap.evenCMap(this.ratio);
    }
  },
  methods: {
    getMatchBet(bet) {
      return findBy(this.match.bets, (b) => {
        if (bet.specialType && bet.specialType === 'FIXED') {
          return b.id === bet.id && b.specialValue === bet.specialValue;
        }
        return b.id === bet.id;
      }) || {};
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/mixins/_flags.scss';
  .top-offer {
    .event-row {
      &.even {
        background-color: $eventRowEven;
        .event-name {
          .home-team {
            &:after {
              background: linear-gradient(to right, transparent, $eventRowEven);
            }
          }
          &:after {
            background: linear-gradient(to right, transparent, $eventRowEven);
          }
        }
      }

      &.odd {
        background-color: $eventRowOdd;
        .event-name {
          .home-team {
            &:after {
              background: linear-gradient(to right, transparent, $eventRowOdd);
            }
          }
          &:after {
            background: linear-gradient(to right, transparent, $eventRowOdd);
          }
        }
      }

      .row-pre-element {
        background-color: $eventRowBackground;

        .row-pre-detail {
          background: $preRowDetail;
        }
      }
    }
  }

  .timelist {
    .event-row {
      &.even {
        background-color: $eventRowEvenTimelist;
        .event-name-details-wrap {
          background: linear-gradient(to left, transparent, $eventRowEvenTimelistLight);
          .event-name {
            &:after {
              background: linear-gradient(to right, transparent, $eventRowEvenTimelist);
            }
          }
        }
      }

      &.odd {
        background-color: $eventRowOddTimelist;
        .event-name-details-wrap {
          background: linear-gradient(to left, transparent, $eventRowOddTimelistLight);
          .event-name {
            &:after {
              background: linear-gradient(to right, transparent, $eventRowOddTimelist);
            }
          }
        }
      }

      .row-pre-element {
        background-color: $eventRowBackgroundTimelist;

        .row-pre-detail {
          background: $preRowDetailTimelist;
        }
      }
    }
  }

  .event-row {
    display:flex;
    flex-direction: row;
    height: $rowHeight;

    .row-inner-wrap,
    .row-odds-wrap {
      display:flex;
      flex-direction: row;
      height: calc(100% - 2px);
      border-top: 1px solid;
      border-bottom: 1px solid;
      position: relative;
    }
    .row-inner-wrap {
      .match-section-name {
        width: $oneThirdWidth;
        display:flex;
        flex-direction: row;
        overflow: hidden;

        @for $i from 1 through 15 {
          @include outcomeOffset($i);
        }

        .row-pre-element {
          height: 100%;
          width: 8px;
          flex-shrink: 0;

          .row-pre-detail {
            height: 100%;
            width: 8px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }

        .event-name-details-wrap {
            display: flex;
            flex-direction: row;
            flex-grow: 1;

          .event-details-meta {
            display: flex;
            flex-direction: row;
          }

          .event-display-id,
          .event-time,
          .event-name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: $largerFont;
            color: $textColor;
            flex-grow: 0;
          }

          .event-time {
            padding: 0 8px 0 16px;
          }

          .flag-wrap {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 12px 0 8px;
            @include flags(1);
          }

          .event-display-id {
            flex-shrink: 0;
            background-color: $eventRowBackground;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            width: calc(100vw / 27);
            padding: 0 12px;
            font-weight: bold;
          }

          .event-name {
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            flex-direction: row;
            flex-grow: 1;
            padding-right: 2px;

            .home-team,
            .dash-separator,
            .away-team {
              display: flex;
              align-items: center;
              overflow: hidden;
            }

            .home-team {
              max-width: calc(50% - (100vw / 128));
              position: relative;

              &.long:after {
                display: inline-flex;
                content: '';
                width: calc(100vw / 64);
                height: 100%;
                position: absolute;
                right: 0px;
              }

              &.flex-width {
                max-width: unset;
                &:after {
                  display: none;
                }
              }

              .home-team-fade-out {
                display: flex;
                content: '';
                width: calc(100vw / 64);
                height: 100%;
                position: absolute;
                right: 0px;
              }
            }
            .dash-separator {
              width: calc(100vw / 64);
              justify-content: center;
            }
            .away-team {
              flex-grow: 1;
              flex-shrink: 1;
              width: 0;
            }

            &:after {
              display: inline-flex;
              content: '';
              width: calc(100vw / 64);
              height: 100%;
              position: absolute;
              right: 0;
              box-shadow: inset -2px 0px 0px -1px rgba(255,255,255,0.3);
            }
          }
        }
      }
    }
    .row-odds-wrap {
      .event-odds-inner {
        display: flex;
        flex-direction: row;
      }
    }
  }
</style>
