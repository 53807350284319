<template>
  <SingleEvent
    :match="match"
  />
</template>

<script>
import SingleEvent from '@/components/SingleEvent/SingleEvent';
import { EventBus } from '@/utility';

export default {
  name: 'EventView',
  props: {
    config: {
      type: Object,
      required: true,
    },
    matches: {
      type: Array,
      required: true,
    },
  },
  components: {
    SingleEvent,
  },
  data() {
    return {
      eventInterval: null,
      eventIndex: 0,
      goToZeroPage: false,
    };
  },
  computed: {
    match() {
      return this.matches[this.eventIndex] || {};
    },
  },
  mounted() {
    EventBus.$on('pageZero', this.setToGoToZeroPage);
    this.restartInterval();
  },
  methods: {
    setToGoToZeroPage() {
      this.goToZeroPage = true;
    },
    removeInterval() {
      clearInterval(this.eventInterval);
      this.eventInterval = null;
    },
    restartInterval() {
      if (this.eventInterval !== null) {
        this.removeInterval();
      }
      this.startInterval();
    },
    startInterval() {
      if (this.eventInterval === null) {
        EventBus.$emit('sectionStarted');
        this.eventInterval = setInterval(() => {
          if (this.eventIndex < (Math.min(this.config.pages, this.matches.length) - 1)) {
            this.eventIndex += 1;
          } else if (this.goToZeroPage) {
            this.eventIndex = 0;
            this.goToZeroPage = false;
          } else {
            EventBus.$emit('sectionFinished');
          }
        }, (this.config.pageDuration * 1000));
      }
    },
  },
  beforeDestroy() {
    EventBus.$off('pageZero', this.setToGoToZeroPage);
    this.removeInterval();
  },
};
</script>
