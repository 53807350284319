<template>
  <div class="sport-wrap">
    <SportHeader
      :idSport="idSport"
      :configId="configId"
      :sportBets="sportBets"
      :outcomesOffset="outcomesOffset"/>
    <div
      class="date-group-wrap"
      v-for="(dateGroupEvents, groupDate) in dateGroups"
      :key="groupDate">
      <OutcomesHeader
        :groupDate="groupDate"
        :idSport="idSport"
        :sportBets="sportBets"
        :outcomesOffset="outcomesOffset"/>
      <EventRow
        v-for="(match, index) in dateGroupEvents"
        :rowIndex="index"
        :key="match.id"
        :configId="configId"
        :match="match"
        :sportBets="sportBets"
        :outcomesOffset="outcomesOffset" />
    </div>
  </div>
</template>

<script>
import SportHeader from '@/components/SportHeader/SportHeader';
import EventRow from '@/components/EventRow/EventRow';
import OutcomesHeader from '@/components/OutcomesHeader/OutcomesHeader';

export default {
  name: 'SportWrap',
  props: {
    idSport: {
      type: String,
      required: true,
    },
    matches: {
      type: Array,
      required: true,
      default: () => [],
    },
    configId: {
      type: String,
      required: true,
    },
  },
  components: {
    EventRow,
    OutcomesHeader,
    SportHeader,
  },
  computed: {
    dateGroups() {
      return groupBy(this.matches, 'date');
    },
    sportBets() {
      return this.$store.getters.sportBets(this.idSport, this.configId);
    },
    outcomesCount() {
      return this.sportBets && sumBy(this.sportBets, b => b.outcomes.length);
    },
    mostBalancedCount() {
      return sumBy(this.sportBets, b => b.specialType && b.specialType === 'MOST_BALANCED') || 0;
    },
    outcomesOffset() {
      return this.outcomesCount ? 16 - (this.outcomesCount + this.mostBalancedCount) : 0;
    },
  },
};
</script>
