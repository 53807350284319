import env from './env';
import appConfig from './config';
import gravitySlug from './companySlugs';
import sentryConfig from './sentry';

const serverEnv = process.env.SERVER || 'staging';
const company = process.env.COMPANY;

const routes = env[serverEnv];

export {
  appConfig,
  company,
  serverEnv,
  routes,
  gravitySlug,
  sentryConfig,
};
