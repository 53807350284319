const map = {
  sports: 'sports',
  categories: 'categories',
  tournaments: 'tournaments',
  matches: 'matches',
  bets: 'bets',
  betGroups: 'betGroups',
  sport: {
    id: 'idSport',
    name: 'sportName',
    shortName: 'sportShortName',
    position: 'sportPosition',
    providerId: 'providerSportId',
    numberOfMatches: 'numberOfMatches',
    bet: {
      id: 'idBet',
      idSport: 'idSport',
      name: 'betName',
      shortName: 'betShortName',
      position: 'betPosition',
      grouping: 'betGroupingEnabled',
      outcomes: 'betOutcomes',
      type: 'betType',
      useMostBalanced: 'useMostBalanced',
      outcome: {
        id: 'idBetOutcome',
        description: 'betOutcomeDescription',
        name: 'betOutcomeName',
        shortName: 'betOutcomeShortName',
        shortcut: 'betOutcomeShortcut',
        idBet: 'idBet',
        providerId: 'providerBetOutcomeId',
        position: 'betOutcomePosition',
      },
    },
  },
  category: {
    id: 'id',
    idSport: 'idSport',
    name: 'categoryName',
    shortName: 'categoryShortName',
    isoId: 'categoryIsoId',
    isoName: 'categoryIsoName',
    position: 'categoryPosition',
    numberOfMatches: 'numberOfMatches',
    providerId: 'providerCategoryId',
  },
  tournament: {
    id: 'id',
    idSport: 'idSport',
    idCategory: 'idCategory',
    name: 'tournamentName',
    shortName: 'tournamentShortName',
    position: 'tournamentPosition',
    topTournament: 'tournamentIsTopOffer',
    providerId: 'providerTournamentId',
    parent: 'parentTournamentId',
    abbreviation: 'tournamentAbbreviation',
  },
  match: {
    id: 'id',
    displayId: 'matchDisplayId',
    sectionId: 'idDisplay',
    name: 'matchName',
    shortName: 'matchShortName',
    idSport: 'idSport',
    idCategory: 'idCategory',
    idTournament: 'idTournament',
    parentTournament: 'parentTournamentId',
    position: 'matchPosition',
    sportPosition: 'sportPosition',
    categoryPosition: 'categoryPosition',
    tournamentPosition: 'tournamentPosition',
    totalBets: 'matchBetsTotal',
    timestampUTC: 'matchDateTimeUTC',
    timestamp: 'matchDateTime',
    tournamentName: 'tournamentName',
    tournamentShortName: 'tournamentShortName',
    isTopOffer: 'matchIsTopOffer',
    betting: 'betting',
    teams: 'matchTeams',
    team: {
      id: 'idTeam',
      type: 'matchTeamType',
      name: 'teamName',
      shortName: 'teamShortName',
      provider: 'providerTeamId',
    },
    bets: 'matchBets',
    bet: {
      id: 'idBet',
      idMatchBet: 'idMatchBet',
      idMatch: 'matchId',
      active: 'matchBetActive',
      position: 'matchBetPosition',
      specialValue: 'matchBetSpecialValue',
      name: 'betName',
      shortName: 'betShortName',
      outcomes: 'matchBetOutcomes',
      mostBalanced: 'mostBalancedValue',
      displayId: 'matchBetDisplayId',
      betGroupingEnabled: 'betGroupingEnabled',
      outcome: {
        id: 'idBetOutcome',
        idMatchBet: 'idMatchBet',
        idMatchBetOutcome: 'idMatchBetOutcome',
        blocked: 'matchBetOutcomeBlocked',
        value: 'matchBetOutcomeOddValue',
        providerId: 'providerBetOutcomeId',
        position: 'matchBetOutcomePosition',
        name: 'betOutcomeName',
        shortName: 'betOutcomeShortName',
        shortcut: 'matchBetOutcomeShortcut',
      },
    },
  },
  betGroup: {
    id: 'idBetGroup',
    active: 'betGroupActive',
    description: 'betGroupDescription',
    name: 'betGroupName',
    shortName: 'betGroupShortName',
    idSport: 'idSport',
    bets: 'bets',
    position: 'betGroupPosition',
  },
};

// eslint-disable-next-line
const shortMap = {
  sports: 's',
  categories: 'c',
  tournaments: 't',
  matches: 'm',
  bets: 'b',
  betGroups: 'bg',
  sport: {
    id: 'a',
    name: 'c',
    shortName: 'e',
    position: 'd',
    providerId: 'b',
    numberOfMatches: 'f',
    bet: {
      id: 'a',
      idSport: 'c',
      name: 'd',
      shortName: 'f',
      position: 'e',
      grouping: 'h',
      outcomes: 'g',
      type: 'i',
      useMostBalanced: 'useMostBalanced',
      outcome: {
        id: 'a',
        description: 'e',
        name: 'd',
        shortName: 'g',
        shortcut: 'betOutcomeShortcut',
        idBet: 'c',
        providerId: 'b',
        position: 'betOutcomePosition',
      },
    },
  },
  category: {
    id: 'a',
    idSport: 'b',
    name: 'c',
    shortName: 'f',
    isoId: 'g',
    isoName: 'h',
    position: 'd',
    numberOfMatches: 'i',
    providerId: 'j',
  },
  tournament: {
    id: 'a',
    idSport: 'h',
    idCategory: 'b',
    name: 'c',
    shortName: 'e',
    position: 'f',
    topTournament: 'g',
    providerId: 'i',
    parent: 'j',
    abbreviation: 'k',
  },
  match: {
    id: 'a',
    displayId: 'b',
    name: 'h',
    shortName: 'j',
    idSport: 'c',
    idCategory: 'd',
    idTournament: 'e',
    parentTournament: 'w',
    position: 'g',
    totalBets: 'm',
    timestampUTC: 'r',
    timestamp: 'f',
    tournamentName: 'o',
    tournamentShortName: 'p',
    isTopOffer: 'n',
    betting: 's',
    teams: 'k',
    team: {
      id: 'a',
      type: 'e',
      name: 'b',
      shortName: 'd',
      provider: 'f',
    },
    bets: 'l',
    bet: {
      id: 'b',
      idMatchBet: 'a',
      idMatch: 'h',
      active: 'm',
      position: 'c',
      specialValue: 'e',
      name: 'n',
      shortName: 'o',
      outcomes: 'i',
      mostBalanced: 'p',
      displayId: 'k',
      outcome: {
        id: 'd',
        idMatchBet: 'b',
        idMatchBetOutcome: 'a',
        blocked: 'f',
        value: 'g',
        providerId: 'c',
        position: 'h',
        name: 'i',
        shortName: 'j',
      },
    },
  },
  betGroup: {
    id: 'a',
    active: 'f',
    description: 'e',
    name: 'c',
    shortName: 'd',
    idSport: 'b',
    bets: 'g',
    position: 'h',
  },
};

// export default process.env.DEBUG ? map : shortMap;
// TODO replace with shortMap once response is shrinked
export default map;
