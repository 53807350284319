import parserMap from './parser-map';
import { maxInt } from '../helpers';

const outcomeMap = parserMap.match.bet.outcome;
const betMap = parserMap.match.bet;
// codebeat:disable[ABC]
class MatchBetGroupedOutcome {
  constructor(rawObj, rawBet) {
    this.id = rawObj[outcomeMap.id];
    this.idMatchBet = rawObj[outcomeMap.idMatchBet];
    this.idMatchBetOutcome = rawObj[outcomeMap.idMatchBetOutcome];
    this.blocked = rawObj[outcomeMap.blocked];
    this.value = parseFloat(rawObj[outcomeMap.value]).toFixed(2);
    this.providerId = rawObj[outcomeMap.providerId];
    this.position = parseInt(rawObj[outcomeMap.position], 10) || maxInt;
    this.name = rawObj[outcomeMap.name];
    this.shortName = rawObj[outcomeMap.shortName] || this.name;
    this.shortcut = rawObj[outcomeMap.shortcut];
    this.specialValue = rawBet[betMap.specialValue];
  }
}
// codebeat:enable[ABC]

export default MatchBetGroupedOutcome;
