<template>
  <div class="template event-view">
    <EventViewHeader :match="match" />
    <div class="event-view-columns-wrap">
      <div
        v-for="(column, index) in eventBets"
        :class="['event-view-bets-column', `column-${index}`]"
        :key="index">
        <EventViewBet
          v-for="(eventBet, index) in column"
          :key="eventBet.idMatchBet || `index-${index}/${eventBet.id}`"
          :eventBet="eventBet"
          />
      </div>
    </div>
  </div>
</template>

<script>
import EventViewHeader from '@/components/EventViewHeader/EventViewHeader';
import EventViewBet from '@/components/EventViewBet/EventViewBet';
import { getEventBetsColumns } from '@/utility/helpers';

export default {
  name: 'SingleEvent',
  props: {
    match: {
      type: Object,
      required: true,
    },
  },
  components: {
    EventViewHeader,
    EventViewBet,
  },
  computed: {
    eventBets() {
      return getEventBetsColumns(this.match);
    },
  },
};
</script>
<style lang="scss">
  .template {
    &.event-view {
      background: $black;
      height: calc(#{$rowHeight} * 19);
    }

    .event-view-columns-wrap {
      display: flex;
      flex-direction: row;
      height: calc(#{$rowHeight} * 17);

      .event-view-bets-column {
        color: white;
        display: flex;
        flex-grow: 1;
        width: calc(100vw / 3);
        flex-direction: column;
        min-width: 0;

        &.column-0 {
          padding: 0 5px 0 2px;
        }
        &.column-1 {
          padding: 0 5px;
        }
        &.column-2 {
          padding: 0 2px 0 5px;
        }
      }
    }
  }
</style>
