<template>
  <div :class="[
    'outcomes-header-bet-outcomes',
    `outcomes-length-${outcomesLength}`,
    { 'most-balanced': mostBalanced }
    ]">
    <BetOutcome
      v-for="outcome  in sportBet.outcomes"
      :key="outcome.id"
      :outcome="outcome" />
  </div>
</template>

<script>
import BetOutcome from './BetOutcome';

export default {
  name: 'BetOutcomes',
  components: {
    BetOutcome,
  },
  props: {
    sportBet: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    mostBalanced() {
      return this.sportBet.specialType && this.sportBet.specialType === 'MOST_BALANCED';
    },
    outcomesLength() {
      return this.mostBalanced
        ? this.sportBet.outcomes.length + 1
        : this.sportBet.outcomes.length;
    },
  },
};
</script>

<style lang="scss">
  .outcomes-header-bet-outcomes {
    display:flex;
    flex-direction: row;
    box-shadow: inset -2px 0px 0px -1px rgba(255,255,255,0.3),
      inset 2px 0px 0px -1px rgba(255,255,255,0.3);

    &:last-child {
      box-shadow: inset 2px 0px 0px -1px rgba(255,255,255,0.3);
    }

    // increase the loop in case of bets with more than 10 outcomes
    @for $i from 1 through 16 {
      &.outcomes-length-#{$i} {
        width: calc(#{$outcomeWidth} * #{$i});
      }
    }

    &.most-balanced {
      justify-content: flex-end;
    }
  }
</style>
