import parserMap from './parser-map';
import { maxInt } from '../helpers';

const { tournament } = parserMap;

class Tournament {
  constructor(rawObj) {
    this.id = rawObj[tournament.id];
    this.idSport = rawObj[tournament.idSport];
    this.idCategory = rawObj[tournament.idCategory];
    this.name = rawObj[tournament.name];
    this.shortName = rawObj[tournament.shortName] || this.name;
    this.position = parseInt(rawObj[tournament.position], 10) || maxInt;
  }
}

export default Tournament;
