import Tournament from './Tournament';
import Category from './Category';
import Sport from './Sport';
import Bet from './Bet';
import Outcome from './Outcome';
import Match from './Match';
import parserMap from './parser-map';

const formatMeta = (rawData) => {
  const sports = {};
  const bets = {};
  const outcomes = {};
  const categories = {};
  const tournaments = {};

  if (rawData[parserMap.sports]) {
    forEach(rawData[parserMap.sports], (value) => {
      const parsedSport = new Sport(value);
      sports[parsedSport.id] = parsedSport;
    });
  }

  if (rawData.outcomes) {
    forEach(rawData.outcomes, (value) => {
      const parsedOutcome = new Outcome(value);
      outcomes[parsedOutcome.id] = parsedOutcome;
    });
  }

  if (rawData[parserMap.bets]) {
    forEach(rawData[parserMap.bets], (value) => {
      const parsedBet = new Bet(value);
      parsedBet.outcomes = filter(outcomes, o => o.idBet === parsedBet.id);
      bets[parsedBet.id] = parsedBet;
    });
  }

  if (rawData[parserMap.tournaments]) {
    forOwn(rawData[parserMap.tournaments], (value) => {
      const parsedTour = new Tournament(value);
      tournaments[parsedTour.id] = parsedTour;
    });
  }

  if (rawData[parserMap.categories]) {
    forOwn(rawData[parserMap.categories], (value) => {
      const parsedCategory = new Category(value);
      categories[parsedCategory.id] = parsedCategory;
    });
  }

  const orderedBets = orderBy(bets, 'position');

  return {
    sports,
    bets: orderedBets,
    categories,
    tournaments,
  };
};

const formatMatches = (rawData) => {
  const matches = [];
  forEach(rawData.data, (value) => {
    const match = new Match(value);
    matches.push(match);
  });
  const sortedMatches = sortBy(
    matches,
    ['sportPosition', 'timestampUTC'],
  );
  return sortedMatches;
};

export default {
  formatMeta,
  formatMatches,
};
