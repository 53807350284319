import parserMap from './parser-map';
import MatchBetOutcome from './MatchBetOutcome';
import { maxInt } from '../helpers';

const betMap = parserMap.match.bet;

// codebeat:disable[ABC]
class MatchBet {
  constructor(rawObj) {
    this.id = rawObj[betMap.id];
    this.displayId = rawObj[betMap.displayId];
    this.idMatchBet = rawObj[betMap.idMatchBet];
    this.idMatch = rawObj[betMap.idMatch];
    this.active = rawObj[betMap.active];
    this.position = parseInt(rawObj[betMap.position], 10) || maxInt;
    this.specialValue = rawObj[betMap.specialValue];
    this.name = rawObj[betMap.name];
    this.shortName = rawObj[betMap.shortName] || this.name;
    this.outcomes = rawObj[betMap.outcomes];
    this.mostBalanced = rawObj[betMap.mostBalanced];
    this.betGroupingEnabled = rawObj[betMap.betGroupingEnabled];

    this.parseOutcomes();
  }

  parseOutcomes() {
    this.outcomes = sortBy(map(this.outcomes, val => new MatchBetOutcome(val)), ['position']);
  }
}

// codebeat:enable[ABC]
export default MatchBet;
