import interpolate from 'color-interpolate';

const evenCMap = interpolate(['#36793a', '#2e3634']);
const oddCMap = interpolate(['#1d6120', '#131b18']);

const colorMap = {
  oddCMap,
  evenCMap,
};

export default colorMap;
