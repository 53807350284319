import getEventBetsColumns from './betColumns';
import getOfferPages from './offerPages';
import sectionsIds from './sectionsIds';
import matchUpdater from './matchUpdater';

const maxInt = Number.MAX_SAFE_INTEGER;

export {
  maxInt,
  getEventBetsColumns,
  getOfferPages,
  matchUpdater,
  sectionsIds,
};
