<template>
  <div :class="['event-bet', outcomesClass ]">
    <div
      class="event-bet-odd special-value"
      v-if="mostBalanced">
      {{ eventBet.specialValue || ' - '}}
    </div>
    <div
      class="event-bet-odd"
      v-for="eventBetOutcome  in betOutcomes"
      :key="eventBetOutcome.id">
      {{ roundOdd(eventBetOutcome.value) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventBet',
  props: {
    eventBet: {
      type: Object,
      required: true,
      default: () => {},
    },
    sportBet: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    mostBalanced() {
      return this.sportBet.specialType && this.sportBet.specialType === 'MOST_BALANCED';
    },
    outcomesClass() {
      return this.mostBalanced
        ? `outcomes-length-${this.sportBet.outcomes.length + 1}`
        : `outcomes-length-${this.sportBet.outcomes.length}`;
    },
    betOutcomes() {
      return this.eventBetOutcomes.length ? this.eventBetOutcomes : this.sportBet.outcomes;
    },
    eventBetOutcomes() {
      return filter(this.eventBet.outcomes, o => some(this.sportBet.outcomes, i => i.id === o.id));
    },
  },
  methods: {
    roundOdd(oddValue) {
      if (!oddValue) return ' - ';
      return parseFloat(oddValue).toPrecision(3);
    },
  },
};
</script>

<style lang="scss">
  .event-bet {
    display:flex;
    flex-direction: row;
    box-shadow: inset -2px 0px 0px -1px rgba(255,255,255,0.3),
      inset 2px 0px 0px -1px rgba(255,255,255,0.3);

    &:last-child {
      box-shadow: inset 2px 0px 0px -1px rgba(255,255,255,0.3);
    }

    // increase the loop in case of bets with more than 10 outcomes
    @for $i from 1 through 16 {
      &.outcomes-length-#{$i} {
        width: calc(#{$outcomeWidth} * #{$i});
      }
    }

    .event-bet-odd {
      width: $outcomeWidth;
      color: $textColor;
      font-size: $oddLargestFont;
      display: flex;
      justify-content: center;
      align-items: center;

      &.special-value {
        font-size: $largerFont;
        color: $pantone;
        font-weight: bold;
        font-family: 'Roboto Condensed Bold'
      }
    }
  }
</style>
