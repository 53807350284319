<template>
  <div
    :class="['header-bet', outcomesClass ]">
    <div class="header-bet-inner-wrap">
      <div class="bet-section-display-id">
        {{ bet.displayId }}
      </div>
      <div
        ref="betNameDiv"
        :class="['bet-section-name', { 'long-name': longBetName }]">
        <span ref="betNameSpan">{{ betName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetHeader',
  props: {
    bet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      longBetName: false,
    };
  },
  computed: {
    outcomesClass() {
      return (this.bet.specialType && this.bet.specialType === 'MOST_BALANCED')
        ? `outcomes-length-${this.bet.outcomes.length + 1}`
        : `outcomes-length-${this.bet.outcomes.length}`;
    },
    betName() {
      return (this.bet.specialType && this.bet.specialType === 'FIXED')
        ? `${this.bet.name} (${this.bet.specialValue})`
        : this.bet.name;
    },
  },
  mounted() {
    this.betNameHeight = this.$refs.betNameDiv.clientHeight;
    this.betNameSpanHeight = this.$refs.betNameSpan.clientHeight;
    this.longBetName = this.betNameSpanHeight > this.betNameHeight;
  },
};
</script>

<style lang="scss">
  .header-bet {
    position: relative;
    display: flex;
    flex-direction: row;
    background: $headerBetBackground;

    // increase the loop in case of bets with more than 10 outcomes
    @for $i from 1 through 16 {
      &.outcomes-length-#{$i} {
        width: calc(#{$outcomeWidth} * #{$i});
      }
    }

    .header-bet-inner-wrap {
      width: 100%;
      display: flex;
      flex-direction: row;

      .bet-section-name {
        font-size: calc(100vw / 96);
        text-transform: uppercase;
        color: $textColor;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 0px 9px;
        overflow: hidden;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.long-name {
          align-items: baseline;

          span {
            padding: 1px 0;
            line-height: calc(100vh / 41);
            overflow: hidden;
          }
        }
      }

      .bet-section-display-id {
        background: $textColor;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        width: calc(100vw / 46.83);
        font-size: $largeFont;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 0 2px;
      }
    }
  }
</style>
