<template>
  <div
    class="loader"
    :class="{ active: isLoading }">
    <div class="spinner" />
  </div>
</template>

<script>
export default {
  name: 'InitLoader',
  computed: {
    isLoading() {
      return this.$store.state.loading;
    },
  },
};
</script>

<style lang="scss">
.loader {
  display: none;
  position: absolute;
  top: 5vh;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $loader-color-back;
  z-index: 100;
  &.active {
    display: block;
  }
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: $loader-size;
  height: $loader-size;
  margin-top: -($loader-size/2);
  margin-left: -($loader-size/2);
  border: $loader-border-size solid $loader-border-color-faded;
  border-top: $loader-border-size solid $loader-border-color;
  border-radius: 50%;
  transform: translateZ(0);
  animation: spin 1.1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
