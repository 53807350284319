import { maxInt } from '../helpers';

class Sport {
  constructor(rawObj) {
    this.id = rawObj.id;
    this.name = rawObj.name;
    this.position = parseInt(rawObj.position, 10) || maxInt;
  }
}

export default Sport;
