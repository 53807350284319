import parserMap from './parser-map';
import Team from './Team';
import MatchBet from './MatchBet';
import MatchBetGroupedOutcome from './MatchBetGroupedOutcome';
import { maxInt, sectionsIds } from '../helpers';
import state from '../../store/state';

const matchMap = parserMap.match;
const betMap = parserMap.match.bet;

const mapGroupedBetOutcomes = (betsz = []) => flatten(map(
  betsz,
  bet => map(bet.matchBetOutcomes, outcome => new MatchBetGroupedOutcome(outcome, bet)),
));

// codebeat:disable[ABC]
const generateGroupedBet = bets => ({
  id: head(bets)[betMap.id],
  displayId: head(bets)[betMap.displayId],
  idMatch: head(bets)[betMap.idMatch],
  active: head(bets)[betMap.active],
  position: parseInt(head(bets)[betMap.position], 10) || maxInt,
  specialValue: head(bets)[betMap.specialValue],
  name: head(bets)[betMap.name],
  shortName: head(bets)[betMap.shortName] || this.name,
  mostBalanced: head(bets)[betMap.mostBalanced],
  betGroupingEnabled: head(bets)[betMap.betGroupingEnabled],
  ungroupedOutcomesLength: head(bets)[betMap.outcomes].length,
  outcomes: orderBy(mapGroupedBetOutcomes(bets), ['specialValue', 'position']),
});

class Match {
  constructor(rawObj, noBets = false, outrights = false) {
    this.id = rawObj[matchMap.id];
    this.displayId = rawObj[matchMap.displayId];
    this.sectionId = rawObj[matchMap.sectionId];
    this.shortName = rawObj[matchMap.shortName] || this.name;
    this.idSport = rawObj[matchMap.idSport];
    this.idCategory = rawObj[matchMap.idCategory];
    this.idTournament = rawObj[matchMap.idTournament];
    this.timestampUTC = rawObj[matchMap.timestampUTC];
    this.position = parseInt(rawObj[matchMap.position], 10) || maxInt;
    this.date = moment(rawObj[matchMap.timestampUTC]).format('DD.MM.YYYY');
    this.month = moment(rawObj[matchMap.timestampUTC]).format('MM');
    this.day = moment(rawObj[matchMap.timestampUTC]).format('DD');
    this.year = moment(rawObj[matchMap.timestampUTC]).format('YYYY');
    this.matchTime = moment(rawObj[matchMap.timestampUTC]).format('HH:mm');
    this.teams = rawObj[matchMap.teams];
    this.sportPosition = parseInt(rawObj[matchMap.sportPosition], 10) || maxInt;
    this.categoryPosition = parseInt(rawObj[matchMap.categoryPosition], 10) || maxInt;
    this.tournamentPosition = parseInt(rawObj[matchMap.tournamentPosition], 10) || maxInt;
    this.sportName = (state.sports[this.idSport] || {}).name;

    if (!noBets) {
      this.bets = rawObj[matchMap.bets] || [];
      this.parseBets();
    }

    if (!outrights) this.parseTeams();
  }

  parseTeams() {
    this.teams = sortBy(map(this.teams, value => new Team(value)), ['type']);
    this.homeTeamShortName = get(findBy(this.teams, { type: '1' }), 'shortName');
    this.awayTeamShortName = get(findBy(this.teams, { type: '2' }), 'shortName');
    delete this.teams;
  }

  filterGroupedBets(grouped = false) {
    return filter(this.bets, { betGroupingEnabled: grouped });
  }

  parseBets() {
    if (this.sectionId === sectionsIds.EVENT_VIEW) {
      const nonGrouped = map(this.filterGroupedBets(false), value => new MatchBet(value));
      const grouped = map(
        groupBy(this.filterGroupedBets(true), 'idBet'),
        (bets, idBetKey) => generateGroupedBet(bets, idBetKey),
      );
      this.bets = JSON.parse(
        JSON.stringify(sortBy([...nonGrouped, ...grouped], ['position', 'name'])),
      );
    } else {
      this.bets = JSON.parse(
        JSON.stringify(sortBy(map(this.bets, value => new MatchBet(value)), ['position', 'name'])),
      );
    }
  }
}
// codebeat:enable[ABC]
export default Match;
