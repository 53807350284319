export default {
  commonParams: companyUuid => ({
    delivery_platform: 'Web',
    company_uuid: companyUuid,
  }),
  configParams: cpvUuid => ({
    cpv: cpvUuid,
    locale: 'en',
    include: 'bets,outcomes,sports,categories,tournaments',
  }),
  pageDisplayMatches: (sections) => {
    const p = {};
    forEach(sections, (val, key) => {
      p[`pageDisplayMatches[${key}][limit]`] = val;
    });
    return p;
  },
  language: lang => ({ default: lang || 'en' }),
  dataFormat: {
    default: 'object',
    sports: 'object',
    categories: 'object',
    tournaments: 'object',
    matches: 'array',
  },
  dataShrink: !process.env.DEBUG,
  topOffer: { matches: true },
  matchesParams: {
    include: 'categories,tournaments',
  },
};
