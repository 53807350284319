import socketio from 'socket.io-client';
import { routes } from '../../config';

let sio;
let connectTimeout = null;
export default {
  instance(cpv, dispatch) {
    if (!sio) {
      const socketOptions = {
        forceNew: true,
        transports: ['websocket'],
      };

      const socketBaseUrl = routes.socket;
      const socketUrl = `${socketBaseUrl}?product=prematch&protocol=sio1&contentEncoding=deflate&cpv=${cpv}`;

      sio = socketio(socketUrl, socketOptions);
      sio.on('connect', () => {
        sio.emit('subscribe', {
          language: 'en',
          deliveryPlatform: 'Retail',
        });
        dispatch('socketConnected');
      });
      sio.on('disconnect', () => dispatch('socketDisconnected'));
      sio.on('message', message => dispatch('socketMessage', message));
    }

    return sio;
  },
  connect() {
    if (sio) sio.connect();
  },
  disconnect() {
    if (sio) sio.disconnect();
  },
  emit({
    event,
    settings,
    options,
  }) {
    switch (event) {
      case 'subscribe':
        sio.emit(event, {
          language: 'en',
          deliveryPlatform: 'Retail',
        });
        break;
      case 'message':
        sio.emit(event, {
          type: options.type,
          channel: settings.company.cpv,
          data: options.data,
        });
        break;
      default:
    }
  },
  bindWindowListeners() {
    window.addEventListener('offline', () => {
      this.disconnect();
    });

    window.addEventListener('online', () => {
      // Add some timeout because of network resolve
      if (connectTimeout !== null) {
        clearTimeout(connectTimeout);
        connectTimeout = null;
      }
      connectTimeout = setTimeout(() => {
        this.connect();
      }, 500);
    });
  },
};
