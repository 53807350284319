<template>
  <div>
    <AppHeader />
    <OfferOverview
      :key="config.id"
      v-if="!isLoading && config.id === sectionsIds.TOP_OFFER"
      :config="config"
      :pages="topOfferPages" />
    <OfferOverview
      :key="config.id"
      v-if="!isLoading && config.id === sectionsIds.TIMELIST"
      :config="config"
      :pages="timelistPages" />
    <EventView
      :config="config"
      :matches="eventViewMatches"
      v-if="!isLoading && config.id === sectionsIds.EVENT_VIEW"
    />
    <InitLoader />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader';
import OfferOverview from '@/layouts/OfferOverview';
import EventView from '@/layouts/EventView';
import InitLoader from '@/components/InitLoader/InitLoader';
import { getOfferPages, sectionsIds } from '@/utility/helpers';
import { socket, translationsCenter, EventBus } from '@/utility';

export default {
  name: 'Prematch',
  data() {
    return {
      sectionIndex: 0,
      sectionFinished: false,
    };
  },
  components: {
    AppHeader,
    EventView,
    OfferOverview,
    InitLoader,
  },
  computed: {
    sections() {
      return this.$store.getters.configSections;
    },
    sectionsIds() {
      return sectionsIds;
    },
    eventsSections() {
      return this.$store.getters.eventsSections;
    },
    currentSectionId() {
      return this.sections[this.sectionIndex];
    },
    config() {
      return (this.currentSectionId && this.$store.getters.configById(this.currentSectionId)) || {};
    },
    isLoading() {
      return this.$store.state.loading;
    },
    topOfferPages() {
      return getOfferPages(
        this.$store.getters.topOfferMatches,
        this.sectionsLimits[this.sectionsIds.TOP_OFFER],
      );
    },
    timelistPages() {
      return getOfferPages(
        this.$store.getters.timelistMatches,
        this.sectionsLimits[this.sectionsIds.TIMELIST],
      );
    },
    eventViewMatches() {
      const { pages } = this.$store.getters.configById(this.sectionsIds.EVENT_VIEW) || {};
      return pages
        ? take(this.$store.getters.eventViewMatches, pages)
        : this.$store.getters.eventViewMatches;
    },
    actualPageLengthPerSection() {
      return {
        [this.sectionsIds.EVENT_VIEW]: size(this.eventViewMatches),
        [this.sectionsIds.TOP_OFFER]: size(this.topOfferPages),
        [this.sectionsIds.TIMELIST]: size(this.timelistPages),
      };
    },
    sectionsLimits() {
      return this.$store.getters.sectionsLimits;
    },
    cpvParam() {
      return this.$store.getters.cpvUuid;
    },
    companyUuid() {
      return this.$store.getters.companyUuid;
    },
    language() {
      return this.$store.getters.language;
    },
    oddsUpdate() {
      return this.$store.getters.oddsUpdate;
    },
    shouldConfigUpdate() {
      return this.$store.getters.shouldConfigUpdate;
    },
  },
  mounted() {
    translationsCenter.get(this.language, this.companyUuid)
      .then(resp => this.$store.dispatch('saveTranslations', resp));
    this.initApp();

    EventBus.$on('sectionFinished', this.sectionFinish);
    EventBus.$on('sectionStarted', this.sectionStarted);
  },
  methods: {
    sectionFinish() {
      this.sectionFinished = true;
      this.updateSectionIndex();
    },
    sectionStarted() {
      this.sectionFinished = false;
    },
    updateSectionIndex() {
      if (this.sectionIndex < (this.sections.length - 1)) {
        this.sectionIndex += 1;
        return;
      }
      if (this.shouldConfigUpdate) {
        this.loadConfig().then(() => {
          this.loadMatches().then(this.restartSections).catch(this.restartSections);
        }).catch(this.restartSections);
        return;
      }
      this.loadMatches().then(this.restartSections).catch(this.restartSections);
    },
    restartSections() {
      this.sectionIndex = 0;
      if (this.sections.length > 1) return;
      EventBus.$emit('pageZero');
    },
    loadConfig() {
      return this.$store.dispatch(
        'loadConfiguration',
        { companyUuid: this.companyUuid },
      );
    },
    loadMatches() {
      return this.$store.dispatch(
        'loadMatches',
        {
          companyUuid: this.companyUuid,
          sectionsLimits: this.sectionsLimits,
        },
      );
    },
    initApp() {
      if (this.cpvParam && this.companyUuid) {
        this.loadConfig().then(() => {
          this.loadMatches().then(() => {
            if (this.oddsUpdate) {
              this.$store.dispatch('socketInstantiate');
            }
          }).catch(() => {});
        }).catch(() => this.$store.dispatch('toggleLoader'));
      }
    },
  },
  beforeDestroy() {
    EventBus.$off('sectionFinished', this.sectionFinish);
    EventBus.$off('sectionStarted', this.sectionStarted);
    if (this.oddsUpdate) {
      socket.disconnect();
    }
  },
};
</script>
