import Vue from 'vue';
import VueRouter from 'vue-router';
import Prematch from '@/pages/Prematch';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: '/',
    },
    {
      path: '/',
      name: 'Prematch',
      component: Prematch,
      props: true,
    },
  ],
});
